import {Stack, Typography} from '@mui/material'

interface TextDetailsProps {
  label: string
  text: string | undefined
}
const TextDetails = ({label, text}: TextDetailsProps) => {
  return (
    <Stack
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{flex: 1, minWidth: 0}}
    >
      <Typography noWrap fontWeight={300}>
        {label}
      </Typography>

      <Typography fontSize={20} fontWeight={200}>
        {text || ''}
      </Typography>
    </Stack>
  )
}

export default TextDetails
