import {useEffect, useState} from 'react'

import {Button, Stack, CircularProgress} from '@mui/material'

import config from 'src/config'

import Attachment from 'src/entities/Attachment'
import UploadDocument from 'src/entities/UploadDocument'
import {downloadBase64File, getFileExtension} from 'src/utils/file'
import {useNavigate} from 'react-router-dom'

import InstitutionDetails from '../Institution/InstitutionDetails'
import {useUpdateFormData} from 'src/contexts/UpdateFormDataContext'
import MissingDocuments from '../missingDocs/MissingDocuments'
import NoMissingDocs from '../missingDocs/NoMissingDocs'
import MissingDocument from 'src/entities/MissingDocument'
import AddMissingDocs from '../missingDocs/AddMissingDocs'
import Comment from 'src/components/custom/Comment'

const MyForm = () => {
  const navigate = useNavigate()

  const [isOpenUploadMissingDocs, setIsOpenUploadMissingDocs] =
    useState<boolean>(false)

  const {updateFormData} = useUpdateFormData()

  const [missingDocuments, setMissingDocument] = useState<
    MissingDocument[] | undefined
  >(updateFormData?.missingDocuments)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [comment, setComment] = useState<string | undefined>(undefined)

  const isUploading = (): boolean => {
    return !missingDocuments?.find(m =>
      m.documentsData?.some(d => d.file !== undefined)
    )
  }

  const onDownloadLastFile = (): void => {
    if (updateFormData?.lastStudentReportFile && updateFormData?.fileName)
      downloadBase64File(
        updateFormData.lastStudentReportFile,
        updateFormData.fileName
      )
  }

  const onUpload = (id: number, month: number, year: number, file: File) => {
    setMissingDocument(prev =>
      prev?.map((m: MissingDocument) =>
        m.month === month && m.year === year
          ? {
              ...m,
              documentsData: m.documentsData?.map((a: Attachment) =>
                a.id === id ? {...a, file} : a
              )
            }
          : m
      )
    )
  }

  const onDelete = (id: number, month: number, year: number) => {
    setMissingDocument(prev =>
      prev?.map((m: MissingDocument) =>
        m.month === month && m.year === year
          ? {
              ...m,
              documentsData: m.documentsData?.map((a: Attachment) =>
                a.id === id ? {...a, file: undefined} : a
              )
            }
          : m
      )
    )
  }

  useEffect(() => {
    setMissingDocument(updateFormData?.missingDocuments)
  }, [updateFormData?.missingDocuments])

  const onSubmit = async () => {
    setIsLoading(true)
    await saveComment()
    uploadAttachments()
  }

  const currentMonth = new Date().getMonth() + 1
  const currentYear = new Date().getFullYear()

  const uploadAttachments = async () => {
    const uploadPromises: any = []
    const institutionId = updateFormData?.institution.id
    missingDocuments?.forEach((missingDocument: MissingDocument) => {
      missingDocument.documentsData?.forEach((attachment: Attachment) => {
        if (attachment.file) {
          uploadPromises.push(
            uploadDocument(
              {
                InstitutionId: institutionId,
                CategoryId: attachment.categoryId,
                Name: attachment.file?.name,
                Extension: getFileExtension(attachment.file?.name),
                Month:
                  attachment.month === -1 ? currentMonth : attachment.month,
                Year: attachment.year || currentYear
              } as UploadDocument,
              attachment.file,
              attachment.id
            )
          )
        }
      })
    })
    const results = await Promise.all(uploadPromises)
    const failedDocuments = results.filter(r => r !== undefined)
    if (failedDocuments.length > 0) {
      navigate(`/error`, {state: {failedDocuments}})
    } else navigate(`/success`)
  }

  const uploadDocument = async (
    documentData: UploadDocument,
    file: File,
    id?: number
  ) => {
    if (id) {
      setMissingDocument(prev =>
        prev?.map((m: MissingDocument) =>
          m.month === documentData.Month && m.year === documentData.Year
            ? {
                ...m,
                documentsData: m.documentsData?.map((a: Attachment) =>
                  a.id === id ? {...a, isUploading: true} : a
                )
              }
            : m
        )
      )
    }
    const formData = new FormData()
    formData.append('InstitutionId', documentData.InstitutionId.toString())
    formData.append('Name', documentData.Name)
    formData.append('CategoryId', documentData.CategoryId.toString())
    formData.append('Extension', documentData.Extension)
    formData.append('Month', documentData?.Month?.toString() || '')
    formData.append('Year', documentData?.Year?.toString())
    formData.append('file', file, file.name)

    for (let i = 0; i < 2; i++) {
      try {
        const response = await fetch(`${config.apiUrl}/Documents/Upload`, {
          method: 'POST',
          body: formData
        })
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return
      } catch (error) {
        if (i === 1) {
          return file.name
        }
      }
    }
    if (id) {
      setMissingDocument(prev =>
        prev?.map((m: MissingDocument) =>
          m.month === documentData.Month && m.year === documentData.Year
            ? {
                ...m,
                documentsData: m.documentsData?.map((a: Attachment) =>
                  a.id === id ? {...a, isUploading: false} : a
                )
              }
            : m
        )
      )
    }
  }

  const saveComment = async () => {
    if (!comment || comment.trim() === '') {
      return
    }
    fetch(
      `${config.apiUrl}/UpdateForm/${updateFormData?.institution.id}/note`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(comment)
      }
    )
      .then((res: any) => {
        return res.json()
      })

      .catch((e: any) => {
        console.log('error: ', e)
      })
  }

  const onAddMissingDocument = () => {
    const mdocument = new MissingDocument()
    mdocument.documentsData = updateFormData?.additionalDoc
      ? [
          {
            ...updateFormData?.additionalDoc,
            id: missingDocuments ? missingDocuments.length + 2 : 1
          }
        ]
      : []

    setMissingDocument(prev => [...(prev || []), mdocument])
  }

  const onOpenUploadMissingDocs = () => {
    setIsOpenUploadMissingDocs(true)
    onAddMissingDocument()
  }

  const onSelectedMonth = (index: number, value: Date | undefined | null) => {
    const month = value ? value.getMonth() + 1 : 0
    const year = value ? value.getFullYear() : 2024

    setMissingDocument(prev =>
      prev?.map((m: MissingDocument, i: number) =>
        i === index
          ? {
              ...m,
              month: month,
              date: value ?? undefined,
              year: year,
              documentsData: m.documentsData?.map((a: Attachment) => ({
                ...a,
                month: month,
                year: year
              }))
            }
          : m
      )
    )
  }

  const handleAddDocument = (month: number, year: number) => {
    setMissingDocument(prev =>
      prev?.map((m: MissingDocument) =>
        m.month === month && m.year === year
          ? {
              ...m,
              documentsData: [
                ...(m.documentsData || []),
                ...(m.documentsData
                  ?.filter(att => att.categoryId === 1)
                  .slice(0, 1)
                  .map(att => ({
                    ...att,
                    file: undefined,
                    id: m.documentsData?.length || 0
                  })) || [])
              ]
            }
          : m
      )
    )
  }

  return (
    <>
      {missingDocuments === undefined || missingDocuments.length === 0 ? (
        <NoMissingDocs onOpenUploadMissingDocs={onOpenUploadMissingDocs} />
      ) : (
        <>
          <InstitutionDetails institution={updateFormData?.institution} />

          <Stack direction="column" spacing={2}>
            {isOpenUploadMissingDocs ? (
              <AddMissingDocs
                onSelectedMonth={onSelectedMonth}
                missingDocuments={missingDocuments}
                onAddMissingDocument={onAddMissingDocument}
                onUpload={onUpload}
                onDelete={onDelete}
              />
            ) : (
              <MissingDocuments
                missingDocuments={missingDocuments}
                onAddDocument={handleAddDocument}
                isShowDownloadLastFile={
                  updateFormData?.fileName !== undefined &&
                  updateFormData?.fileName !== ''
                }
                onUpload={onUpload}
                onDelete={onDelete}
                onDownloadLastFile={onDownloadLastFile}
              />
            )}
            <Comment comment={comment} setComment={setComment} />
            <Stack sx={{padding: 2}}>
              <Button
                variant="contained"
                color="primary"
                sx={{mt: 2, position: 'fixed', bottom: '20px', left: '20px'}}
                onClick={onSubmit}
                startIcon={isLoading && <CircularProgress size={20} />}
                disabled={isLoading || isUploading()}
              >
                אישור
              </Button>
            </Stack>
          </Stack>
        </>
      )}
    </>
  )
}

export default MyForm
