import React, {createContext, useContext, useState, ReactNode} from 'react'

// Define the enum
enum loginStatusEnum {
  noAuth,
  sendOtp,
  invalidOtp,
  successAuth
}

// Define the context type
interface AuthContextType {
  loginStatus: loginStatusEnum
  setLoginStatus: React.Dispatch<React.SetStateAction<loginStatusEnum>>
}

// Create the context with a default value
const AuthContext = createContext<AuthContextType | undefined>(undefined)

// Create a provider component
const AuthProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [loginStatus, setLoginStatus] = useState<loginStatusEnum>(
    loginStatusEnum.noAuth
  )

  return (
    <AuthContext.Provider value={{loginStatus, setLoginStatus}}>
      {children}
    </AuthContext.Provider>
  )
}

// Custom hook for using the context
const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export {AuthProvider, useAuth, loginStatusEnum}
