// scroll bar
import 'simplebar/src/simplebar.css'

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'

import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
// contexts
import {SettingsProvider} from './contexts/SettingsContext'

//
import App from './App'

// ----------------------------------------------------------------------

ReactDOM.render(
  <SettingsProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </SettingsProvider>,
  document.getElementById('root')
)
