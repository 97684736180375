import {Button, Grid, Stack, Typography} from '@mui/material'
import File from 'src/components/ui/File'
import Attachment from 'src/entities/Attachment'
import MissingDocument from 'src/entities/MissingDocument'
import AddIcon from '@mui/icons-material/Add'

interface MissingDocumentsProps {
  isShowDownloadLastFile: boolean
  missingDocuments?: MissingDocument[]
  onUpload: (id: number, month: number, year: number, file: File) => void
  onDelete: (id: number, month: number, year: number) => void
  onAddDocument: (month: number, year: number) => void
  onDownloadLastFile: () => void
}

const MissingDocuments = ({
  missingDocuments,
  onUpload,
  onDelete,
  onAddDocument,
  isShowDownloadLastFile,
  onDownloadLastFile
}: MissingDocumentsProps) => {
  return (
    <Stack spacing={2} sx={{mt: 6}}>
      <Typography variant="h6">צירוף מסמכים שחסרים במערכת:</Typography>

      {missingDocuments?.map((m: MissingDocument, index) => (
        <Grid container spacing={3} alignItems="center" key={index}>
          <Grid container spacing={3} alignItems="stretch">
            {m.documentsData?.map((att: Attachment) => (
              <Grid
                item
                xs={4}
                key={att.id}
                sx={{display: 'flex', flexDirection: 'column'}}
              >
                <File
                  attachment={att}
                  onUpload={(id: number, file: File) => {
                    onUpload(id, m.month, m.year, file)
                  }}
                  onDelete={(id: number) => onDelete(id, m.month, m.year)}
                  onDownloadLastFile={onDownloadLastFile}
                  isShowDownloadLastFile={
                    att.categoryId === 6 && isShowDownloadLastFile
                  }
                />
              </Grid>
            ))}
            {m.documentsData?.find(d => d.categoryId === 1) && (
              <Grid item xs={2} key={index}>
                <Button
                  color="primary"
                  variant="outlined"
                  sx={{whiteSpace: 'nowrap', padding: '0.5px'}}
                  startIcon={<AddIcon sx={{ml: 1, mr: -1}} />}
                  onClick={() => onAddDocument(m.month, m.year)}
                >
                  הוספת דו”ח תים נוסף לחודש זה
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
    </Stack>
  )
}

export default MissingDocuments
