import {Stack, Paper, Typography, Box} from '@mui/material'
import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {loginStatusEnum, useAuth} from 'src/contexts/AuthContext'
import Logo from './custom/Logo'

const ErrorPage = () => {
  const {setLoginStatus} = useAuth()

  useEffect(() => {
    setLoginStatus(loginStatusEnum.noAuth)
  }, [setLoginStatus])

  const location = useLocation()
  const {failedDocuments} =
    (location.state as {failedDocuments: string[]}) || {}

  const docText = failedDocuments?.length > 1 ? 'קבצים' : 'קובץ'
  return (
    <>
      <Logo />

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="97vh"
      >
        <Paper
          elevation={3}
          sx={{
            p: '2rem',
            width: '30%',
            borderRadius: '16px'
          }}
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Typography
              variant="h5"
              sx={{
                fontFamily: 'Heebo',
                pt: '2rem',
                fontWeight: 'bold'
              }}
            >
              {` ארעה שגיאה בהעלאת ה${docText} `}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontFamily: 'Heebo',
                textAlign: 'center',
                color: '#637381'
              }}
            >
              {failedDocuments?.map((d, index) => (
                <>{`${index > 0 ? ' ו' : ''}קובץ ${d}`}</>
              ))}
              {` נכשל${failedDocuments?.length > 1 ? 'ו' : ''} בהעלאה`}
              <br />
              {`נא לשלוח את ה${docText} למייל:`}{' '}
              <a color="grey" href="mailto:office@koh.org.il">
                office@koh.org.il
              </a>
              <br />
            </Typography>
          </Stack>
        </Paper>
      </Box>
    </>
  )
}

export default ErrorPage
