import {useState} from 'react'
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Link,
  TextField,
  Typography
} from '@mui/material'

import config from 'src/config'
import UpdateFormData from 'src/entities/UpdateFormData'
import {loginStatusEnum, useAuth} from 'src/contexts/AuthContext'

interface SendOtpPopupProps {
  institutionId: string | undefined
  idNumber: string | undefined
  onSave: (data: UpdateFormData) => void
  sendAgainOtp: () => Promise<Response>
}

const SendOtpPopup = function SendOtpPopup({
  institutionId,
  idNumber,
  onSave,
  sendAgainOtp
}: SendOtpPopupProps) {
  const [otp, setOtp] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const {setLoginStatus} = useAuth()

  const sendOtp = async (event: any) => {
    event.preventDefault()
    var res = await sendAgainOtp()
    console.log(res)
  }
  const checkOtp = async () => {
    if (!otp) {
      setOtp('')
      return
    }

    setIsLoading(true)
    setErrorMessage(null)

    fetch(
      `${config.apiUrl}/UpdateForm/validateOtp?IndificationNo=${idNumber}&institutionId=${institutionId}&otp=${otp}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(res => {
        if (res.status === 401) {
          setErrorMessage('הסיסמה שהכנסת אינה נכונה. אולי טעית בהקלדה?')
          return
        } else if (res.status !== 200) {
          throw new Error(`Unexpected response status: ${res.status}`)
        }

        return res.json()
      })
      .then((data: any) => {
        if (data) {
          setLoginStatus(loginStatusEnum.successAuth)

          onSave(new UpdateFormData(data))
        }
      })
      .catch((e: any) => {
        if (e.status === 401) {
          setErrorMessage('הסיסמה שהכנסת אינה נכונה. אולי טעית בהקלדה?')
        } else {
          setErrorMessage('התהליך נכשל, נסה שוב.')
        }
        console.log('error: ', e)

        setLoginStatus(loginStatusEnum.invalidOtp)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Backdrop
      open
      sx={{position: 'absolute', zIndex: theme => theme.zIndex.snackbar + 1}}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            bgcolor: 'background.paper',
            p: 4,
            borderRadius: 2,
            boxShadow: 3,
            textAlign: 'center',
            position: 'relative'
          }}
        >
          <Typography variant="h5" component="h1" gutterBottom>
            הסיסמה נשלחה למייל
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            יש לבדוק במייל שהוזן במערכת
          </Typography>

          <TextField
            label="סיסמה"
            variant="outlined"
            fullWidth
            margin="normal"
            type={'password'}
            value={otp || ''}
            onChange={e => {
              setOtp(e.target.value)
              setErrorMessage('')
            }}
            error={otp?.trim() === ''}
            helperText={otp?.trim() === '' ? 'חובה להכניס סיסמא' : ''}
          />

          {errorMessage && (
            <Alert
              severity="error"
              sx={{
                alignItems: 'center',
                '& .MuiAlert-message': {flexGrow: 1},
                '& .MuiAlert-action': {padding: 0},
                '& .MuiAlert-icon': {m: 0, pl: 1}
              }}
            >
              {errorMessage}
            </Alert>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{mt: 2}}
            onClick={checkOtp}
            endIcon={isLoading && <CircularProgress size={20} />}
            disabled={!otp || otp?.trim() === ''}
          >
            כניסה
          </Button>
          <Link sx={{pt: 4}} href="" color="inherit" onClick={sendOtp}>
            שלח שוב
          </Link>
        </Box>
      </Container>
    </Backdrop>
  )
}

export default SendOtpPopup
