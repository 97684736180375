import {useRoutes} from 'react-router-dom'
import Main from 'src/components/Main'
import SuccessPage from 'src/components/SuccessPage'
import ErrorPage from 'src/components/ErrorPage'

import ProtectedRoute from '../login/ProtectedRoutes'

export default function Router() {
  return useRoutes([
    {
      path: '/success',
      element: <SuccessPage />
    },
    {
      path: '/error',
      element: <ErrorPage />
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Main />
        </ProtectedRoute>
      )
    }
  ])
}
