import {Box, Button, Grid, Stack, TextField, Typography} from '@mui/material'
import MissingDocument from 'src/entities/MissingDocument'
import AddIcon from '@mui/icons-material/Add'
import File from 'src/components/ui/File'
import {DatePicker} from '@mui/x-date-pickers'

interface AddMissingDocsProps {
  missingDocuments?: MissingDocument[]
  onUpload: (id: number, month: number, year: number, file: File) => void
  onDelete: (id: number, month: number, year: number) => void
  onAddMissingDocument: () => void
  onSelectedMonth: (index: number, value: Date | undefined | null) => void
}

const AddMissingDocs = ({
  missingDocuments,
  onAddMissingDocument,
  onSelectedMonth,
  onUpload,
  onDelete
}: AddMissingDocsProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h6">הוספת דו”ח תים:</Typography>
      {missingDocuments?.map((m: MissingDocument, index) => (
        <Grid container spacing={3} alignItems="center" key={index}>
          <Grid container spacing={3} alignItems="stretch">
            {m.documentsData && (
              <>
                <Grid
                  item
                  xs={4}
                  key={m.documentsData?.[0].id}
                  sx={{display: 'flex', flexDirection: 'column'}}
                >
                  <DatePicker
                    minDate={new Date(2024, 5, 1)}
                    maxDate={new Date()}
                    inputFormat="MM/yyyy"
                    openTo="month"
                    views={['year', 'month']}
                    label="בחירת חודש"
                    value={m.date}
                    onChange={newValue => onSelectedMonth(index, newValue)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        InputLabelProps={{
                          sx: {textAlign: 'right', width: '100%'}
                        }}
                        InputProps={{
                          ...params.InputProps,
                          placeholder: 'בחר חודש'
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  key={m.documentsData?.[0].id}
                  sx={{display: 'flex', flexDirection: 'column'}}
                >
                  <File
                    attachment={m.documentsData[0]}
                    onUpload={(id: number, file: File) => {
                      onUpload(id, m.month, m.year, file)
                    }}
                    onDelete={(id: number) => onDelete(id, m.month, m.year)}
                    isShowDownloadLastFile={false}
                    isDisable={!m.date}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      ))}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'flex-end',
          width: '100%',
          mt: 2
        }}
      >
        <Button
          color="primary"
          variant="outlined"
          sx={{whiteSpace: 'nowrap', padding: '0.5px', mt: 2}}
          startIcon={<AddIcon sx={{ml: 1, mr: -1}} />}
          onClick={() => onAddMissingDocument()}
        >
          הוספת דו”ח תים נוסף
        </Button>
      </Box>
    </Stack>
  )
}
export default AddMissingDocs
