import {useState} from 'react'
import LoginPopup from './LoginPopup'
import SendOtpPopup from './SendOtpPopup'

import UpdateFormData from 'src/entities/UpdateFormData'
import {useUpdateFormData} from 'src/contexts/UpdateFormDataContext'
import config from 'src/config'
import {loginStatusEnum, useAuth} from 'src/contexts/AuthContext'
import Logo from '../custom/Logo'

const ProtectedRoute = function ProtectedRoute({children}: any) {
  const {setUpdateFormData} = useUpdateFormData()

  const {loginStatus} = useAuth()

  const [institutionId, setInstitutionId] = useState<string | undefined>(
    undefined
  )
  const [idNumber, setIdNumber] = useState<string | undefined>(undefined)

  const sendOtp = async () => {
    const res = await fetch(
      `${config.apiUrl}/UpdateForm/sendOtp?IndificationNo=${idNumber}&institutionId=${institutionId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    return res
  }

  const onSave = (data: UpdateFormData) => {
    setUpdateFormData(data)
  }

  if (loginStatus === loginStatusEnum.noAuth)
    return (
      <>
        <Logo />
        <LoginPopup
          setIdNumber={setIdNumber}
          setInstitutionId={setInstitutionId}
          institutionId={institutionId}
          idNumber={idNumber}
          sendOtp={sendOtp}
        />{' '}
      </>
    )
  if (loginStatus === loginStatusEnum.sendOtp)
    return (
      <>
        <Logo />
        <SendOtpPopup
          institutionId={institutionId}
          idNumber={idNumber}
          onSave={onSave}
          sendAgainOtp={sendOtp}
        />{' '}
      </>
    )

  if (loginStatus === loginStatusEnum.successAuth) return children

  return null
}

export default ProtectedRoute
