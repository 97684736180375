import {
  Alert,
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  TextField,
  Typography
} from '@mui/material'

import {useState} from 'react'

import {loginStatusEnum, useAuth} from 'src/contexts/AuthContext'
import {isValidIsraeliID} from 'src/utils/validator'

interface LoginPopupProps {
  setInstitutionId: React.Dispatch<React.SetStateAction<string | undefined>>
  setIdNumber: React.Dispatch<React.SetStateAction<string | undefined>>
  institutionId: string | undefined
  idNumber: string | undefined
  sendOtp: () => Promise<Response>
}

const LoginPopup = function LoginPopup({
  setInstitutionId,
  setIdNumber,
  institutionId,
  idNumber,
  sendOtp
}: LoginPopupProps) {
  const [errors, setErrors] = useState<{[key: string]: string}>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const {setLoginStatus} = useAuth()

  const handleIdNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target
    setIdNumber(value)
    checkIdNumber(value, errors)
  }

  const checkIdNumber = (
    idNumber: string | undefined,
    newErrors: {[key: string]: string}
  ) => {
    if (idNumber?.trim() === '') {
      newErrors.idNumber = 'שדה חובה'
    } else if (!isValidIsraeliID(idNumber)) {
      newErrors.idNumber = 'ת.ז של איש קשר לא תקינה'
    } else {
      newErrors.idNumber = ''
    }
    setErrors(newErrors)
  }
  const validateForm = () => {
    const newErrors: {[key: string]: string} = {}
    newErrors.institutionId = ''

    checkIdNumber(idNumber, newErrors)
    if (institutionId === undefined || institutionId?.trim() === '') {
      newErrors.institutionId = 'שדה חובה'
    }

    setErrors(newErrors)
    return newErrors.institutionId === '' && newErrors.idNumber === ''
  }

  const submit = async () => {
    if (!validateForm()) return

    setIsLoading(true)
    setErrorMessage(null)

    try {
      const res = await sendOtp()

      if (res.status === 200) {
        setLoginStatus(loginStatusEnum.sendOtp)
      } else if (res.status === 404) {
        setErrorMessage(
          '  הפרטים לא תואמים למידע שקיים אצלנו, נא לוודא שאלו פרטים נכונים '
        )
        setLoginStatus(loginStatusEnum.noAuth)
      } else {
        throw new Error(`Unexpected response status: ${res.status}`)
      }
    } catch (e: any) {
      console.log('error: ', e)
      setErrorMessage('ארעה שגיאה ,אנא נסה שוב.')
      setLoginStatus(loginStatusEnum.noAuth)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Backdrop
      open
      sx={{position: 'absolute', zIndex: theme => theme.zIndex.snackbar + 1}}
    >
      <Card>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography component="h1" variant="h5">
            עדכון נתונים לקרן עולם התורה
          </Typography>
          <Stack sx={{mt: 2, width: 450}}>
            <TextField
              label="קוד מוסד"
              variant="outlined"
              value={institutionId}
              fullWidth
              type="number"
              margin="normal"
              onChange={e => setInstitutionId(e.target.value)}
              inputProps={{style: {textAlign: 'right'}}}
              error={!!errors.institutionId}
              helperText={errors.institutionId}
            />
            <TextField
              label="ת.ז של איש קשר"
              variant="outlined"
              fullWidth
              value={idNumber}
              margin="normal"
              onChange={handleIdNumber}
              inputProps={{style: {textAlign: 'right'}}}
              error={!!errors.idNumber}
              helperText={errors.idNumber}
            />
            {errorMessage && (
              <Alert
                severity="error"
                sx={{
                  alignItems: 'center',
                  '& .MuiAlert-message': {flexGrow: 1},
                  '& .MuiAlert-action': {padding: 0},
                  '& .MuiAlert-icon': {m: 0, pl: 1}
                }}
              >
                {errorMessage}
              </Alert>
            )}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{mt: 2}}
              onClick={submit}
              endIcon={isLoading && <CircularProgress size={20} />}
              disabled={
                !institutionId ||
                !idNumber ||
                errors.idNumber !== '' ||
                isLoading
              }
            >
              כניסה
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Backdrop>
  )
}

export default LoginPopup
