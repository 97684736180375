import Attachment from './Attachment'
import Institution from './Institution'
import MissingDocument from './MissingDocument'

export default class UpdateFormData {
  institution: Institution

  lastStudentReportFile?: string

  fileName?: string

  missingDocuments: MissingDocument[]

  additionalDoc?: Attachment

  /**
   *
   */
  constructor(data: any) {
    this.institution = new Institution(data.Institution)
    this.lastStudentReportFile = data.LastStudentReportFile
    this.fileName = data.FileName
    this.missingDocuments = data?.MissingDocuments?.map(
      (d: any) => new MissingDocument(d)
    )
    this.additionalDoc = data.AdditionalDoc
      ? new Attachment(data.AdditionalDoc)
      : undefined
  }
}
