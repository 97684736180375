export function dataUrlToFile(dataurl: string, fileName: string) {
  const arr = dataurl.split(',')
  const mime = arr[0]?.match(/:(.*?);/)?.[1]
  const byteCharacters = atob(arr[1])
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const blob = new Blob([byteArray], {type: mime})
  return new File([blob], fileName, {type: mime})
}

export function getFileExtension(fileName: string) {
  return fileName.substring(fileName.lastIndexOf('.') + 1)
}

export function downloadBase64File(base64String: string, fileName: string) {
  const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`
  const downloadLink = document.createElement('a')
  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
}
