import rtlPlugin from 'stylis-plugin-rtl'
import {CacheProvider} from '@emotion/react'
import createCache from '@emotion/cache'

const EmotionRtl = ({children}: any) => {
  // Create rtl cache
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [rtlPlugin]
  })

  document.body.dir = 'rtl'

  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>
}

export default EmotionRtl
