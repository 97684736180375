import React, {createContext, useState, useContext, ReactNode} from 'react'
import UpdateFormData from 'src/entities/UpdateFormData'

interface UpdateFormDataContextProps {
  updateFormData: UpdateFormData | null
  setUpdateFormData: (data: UpdateFormData) => void
}

const UpdateFormDataContext = createContext<
  UpdateFormDataContextProps | undefined
>(undefined)

export const useUpdateFormData = () => {
  const context = useContext(UpdateFormDataContext)
  if (!context) {
    throw new Error(
      'useUpdateFormData must be used within a UpdateFormDataProvider'
    )
  }
  return context
}

export const UpdateFormDataProvider = ({children}: {children: ReactNode}) => {
  const [updateFormData, setUpdateFormData] = useState<UpdateFormData | null>(
    null
  )

  return (
    <UpdateFormDataContext.Provider value={{updateFormData, setUpdateFormData}}>
      {children}
    </UpdateFormDataContext.Provider>
  )
}
