import {ChangeEvent} from 'react'
import {
  FormControl,
  IconButton,
  Link,
  Stack,
  Typography,
  TextField,
  CircularProgress,
  Alert,
  Tooltip
} from '@mui/material'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import {ReactComponent as DeleteIcon} from 'src/images/delete.svg'
import Attachment from 'src/entities/Attachment'
import {grey} from '@mui/material/colors'

export interface FileProps {
  attachment: Attachment
  isShowDownloadLastFile: boolean
  onUpload: (id: number, file: File) => void
  onDelete: (id: number) => void
  onDownloadLastFile?: () => void
  isDisable?: boolean
}
const File = (props: FileProps) => {
  const {
    attachment,
    onUpload,
    onDelete,
    isShowDownloadLastFile,
    onDownloadLastFile,
    isDisable
  } = props

  const onUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const {files} = event.target

    if (files) {
      onUpload(attachment.id, files[0])
    }

    event.target.value = ''
    event.preventDefault()
  }

  const onDeleteFile = () => {
    onDelete(attachment.id)
  }

  const onDownload = (event: any) => {
    event.preventDefault()
    if (onDownloadLastFile) onDownloadLastFile()
  }

  return (
    <FormControl sx={{width: '100%'}}>
      <Stack spacing={0.5}>
        <Typography
          variant="caption"
          fontSize={13}
          sx={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2, // Limits to 2 lines
            textOverflow: 'ellipsis',
            lineHeight: '1.5em', // Adjust based on your font-size
            maxHeight: '3em', // 2 lines * 1.5em (line-height)
            minHeight: '3em' // Always 2 lines high
          }}
          component="div"
        >
          {attachment.description}
        </Typography>
        <TextField
          disabled={isDisable === true}
          variant="outlined"
          type="text"
          value={
            attachment.isUploading
              ? 'המסמך עולה'
              : attachment.file
              ? attachment.file?.name
              : 'העלאת מסמך'
          }
          InputProps={{
            readOnly: true,
            style: {
              paddingLeft: 8,
              width: '100%',
              fontSize: 12,
              color: grey[600]
            },
            endAdornment: (
              <Tooltip
                title={isDisable ? 'עליך לבחור תאריך לפני העלאת מסמך' : ''}
                disableHoverListener={!isDisable}
              >
                <IconButton
                  component={'label'}
                  sx={{borderLeft: 1, borderRadius: 0}}
                >
                  {attachment.isUploading ? (
                    <CircularProgress sx={{color: '#0C4968'}} size={24} />
                  ) : attachment.file ? (
                    <DeleteIcon
                      width={24}
                      height={24}
                      onClick={e => {
                        e.preventDefault()
                        onDeleteFile()
                      }}
                    />
                  ) : (
                    <>
                      <AttachFileIcon />
                      <input
                        style={{display: 'none'}}
                        type="file"
                        disabled={isDisable}
                        hidden
                        accept={attachment.allowFormat}
                        onChange={onUploadFile}
                      />
                    </>
                  )}
                </IconButton>
              </Tooltip>
            )
          }}
        />
        {attachment.descriptionAfter && (
          <Typography variant="caption">
            {attachment.descriptionAfter}
          </Typography>
        )}

        {props.attachment.isRequierd && !props.attachment.file && (
          <Typography sx={{color: '#d32f2f', fontSize: '0.75rem'}}>
            חובה להעלות קובץ
          </Typography>
        )}
        {(attachment.isNotApproved || isShowDownloadLastFile) && (
          <Alert
            severity="error"
            sx={{
              padding: '0px',
              fontSize: '0.75rem',
              alignItems: 'center',
              '& .MuiAlert-message': {flexGrow: 0},
              '& .MuiAlert-action': {padding: 0},
              '& .MuiAlert-icon': {m: 0, pl: 1}
            }}
          >
            {attachment.isNotApproved
              ? 'הדו”ח שצורף בעבר לא תקין. יש להעלות חדש'
              : 'יש להוריד את הדו”ח הקיים לעדכן את השינויים ולהעלות את הקובץ מחדש'}
          </Alert>
        )}
        {!attachment.isNotApproved && isShowDownloadLastFile && (
          <Link variant="caption" href="" onClick={onDownload}>
            יש ללחוץ על מנת להוריד את הטופס למלא ולצרף.
          </Link>
        )}
      </Stack>
    </FormControl>
  )
}

export default File
