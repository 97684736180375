import Form from 'src/components/form/Form'
import {Stack, Paper, Typography, Divider} from '@mui/material'
import {CodeTableEnum} from 'src/enum/lists'
import config from 'src/config'

export const fetchData = async (url: string) => {
  try {
    const res = await fetch(url, {})

    if (!res.ok) {
      throw new Error('Failed to fetch')
    }
    return await res.json()
  } catch (error) {
    console.error('Error fetching data:', error.message)
    return null
  }
}

export const fetchList = async (listId: CodeTableEnum) => {
  try {
    const list = await fetchData(`${config.apiUrl}/CodeTables/${listId}`)
    return list
  } catch {
    return []
  }
}

const FormWrapper = () => {
  return (
    <Stack
      sx={{
        width: '85%',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Paper
        elevation={3}
        sx={{
          pr: '2rem',
          pl: '2rem',
          width: '100%',
          borderRadius: '16px'
        }}
      >
        <Stack direction="column" spacing={2}>
          <Typography
            variant="h5"
            sx={{
              fontFamily: 'Heebo',
              pt: '2rem',
              fontWeight: 'bold'
            }}
          >
            עידכון טפסים
          </Typography>
          <Divider />

          <Form />
        </Stack>
      </Paper>
    </Stack>
  )
}
export default FormWrapper
