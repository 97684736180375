import Attachment from './Attachment'

export default class MissingDocument {
  month: number

  year: number

  documentsData?: Attachment[]

  date?: Date | null = null

  constructor(institution?: any) {
    this.month = institution?.Month

    this.year = institution?.Year

    if (institution?.DocumentsData)
      this.documentsData = institution?.DocumentsData?.map(
        (d: any) => new Attachment(d, institution.Month, institution.Year)
      )
    else {
      this.documentsData = []
    }
  }
}
