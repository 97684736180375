import {Button, Stack, Typography} from '@mui/material'
import {grey} from '@mui/material/colors'
import {ReactComponent as DocsIcon} from 'src/images/docs.svg'

interface NoMissingDocsProps {
  onOpenUploadMissingDocs: () => void
}

const NoMissingDocs = ({onOpenUploadMissingDocs}: NoMissingDocsProps) => {
  return (
    <Stack alignItems={'center'} spacing={2}>
      <DocsIcon />
      <Typography variant="h5"> כל המסמכים במערכת מעודכנים</Typography>
      <Typography variant="body1" align="center" color={grey[700]}>
        אין צורך במסמכים נוספים.
      </Typography>
      <Button
        sx={{width: '25%', alignItems: 'center'}}
        variant="contained"
        onClick={onOpenUploadMissingDocs}
      >
        להוספת דו”חות תים
      </Button>
    </Stack>
  )
}
export default NoMissingDocs
