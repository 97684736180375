export default class Attachment {
  id: number

  formId: number

  categoryId?: number

  description?: string

  descriptionAfter?: string

  isRequierd?: boolean

  isNotApproved?: boolean

  urlDownload?: string

  allowFormat?: string

  order?: number

  file?: File

  isUploading?: boolean

  month?: number

  year?: number

  /**
   *
   */
  constructor(attachment: any, amonth?: number, ayear?: number) {
    this.id = attachment.Id
    this.formId = attachment.FormId
    this.categoryId = attachment.CategoryId
    this.description = attachment.Description
    this.descriptionAfter = attachment.DescriptionAfter
    this.isRequierd = attachment.IsRequierd
    this.isNotApproved = attachment.IsNotApproved
    this.urlDownload = attachment.UrlDownload
    this.allowFormat = attachment.AllowFormat
    this.order = attachment.Order
    this.file = attachment.File
    this.isUploading = attachment.IsUploading
    this.month = attachment.Month || amonth
    this.year = attachment.Year || ayear
  }
}
