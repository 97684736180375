import {Stack, TextField, Typography} from '@mui/material'

interface CommentsProps {
  comment?: string
  setComment: React.Dispatch<React.SetStateAction<string | undefined>>
}

const Comment = ({comment, setComment}: CommentsProps) => {
  return (
    <>
      <Stack>
        <Typography fontSize="18px" fontWeight="700">
          הערות:
        </Typography>
        <TextField
          fullWidth
          value={comment}
          id="outlined-textarea"
          placeholder="כתוב כאן את ההערות שלך..."
          multiline
          rows={4}
          onChange={e => {
            setComment(e.target.value)
          }}
          onKeyDown={e => {
            // Prevent focus shift to menu items when typing
            e.stopPropagation()
          }}
        />
      </Stack>
    </>
  )
}
export default Comment
