import {Box, Stack} from '@mui/material'
import FormWrapper from 'src/components/FormWrapper'
import {ReactComponent as Logo} from 'src/images/logo.svg'

const Main = () => {
  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Stack direction="column" spacing={4}>
        <Stack
          sx={{
            height: '100px',
            width: '100%',
            background: '#0C4A68',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Logo />
        </Stack>
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <FormWrapper />
        </Stack>
      </Stack>
    </Box>
  )
}

export default Main
