// theme

// components
import MotionLazyContainer from './components/animate/MotionLazyContainer'

import DirectionWrapper from 'src/components/DirectionWrapper'
import Router from 'src/components/routes/root'
import ThemeProvider from './theme'
import {UpdateFormDataProvider} from './contexts/UpdateFormDataContext'
import {AuthProvider} from './contexts/AuthContext'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'

// ----------------------------------------------------------------------

export default function App() {
  return (
    <DirectionWrapper>
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeProvider>
            <AuthProvider>
              <UpdateFormDataProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Router />
                </LocalizationProvider>
              </UpdateFormDataProvider>
            </AuthProvider>
          </ThemeProvider>
        </ThemeProvider>
      </MotionLazyContainer>
    </DirectionWrapper>
  )
}
