export default class Institution {
  id: number

  name: string

  typeId?: number

  typeDescription?: string

  institutionHeadName?: string

  street?: string

  houseNumber?: string

  zipCode?: string

  city?: string

  phone?: string

  constructor(institution: any) {
    this.id = institution.Id
    this.typeDescription = institution.TypeDescription
    this.name = institution.Name
    this.typeId = institution.TypeId
    this.institutionHeadName = institution.InstitutionHeadName
    this.street = institution.Street
    this.houseNumber = institution.HouseNumber
    this.zipCode = institution.ZipCode
    this.city = institution.City
    this.phone = institution.Phone
  }
}
