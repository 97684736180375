import {Stack} from '@mui/material'
import {ReactComponent as LogoIcon} from 'src/images/logo.svg'

const Logo = () => {
  return (
    <Stack
      sx={{
        height: '100px',
        width: '100%',
        background: '#0C4A68',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <LogoIcon />
    </Stack>
  )
}

export default Logo
