import {Stack, Paper, Typography, Box} from '@mui/material'
import {ReactComponent as SuccessIcon} from 'src/images/success_icon.svg'
import Logo from './custom/Logo'
import {loginStatusEnum, useAuth} from 'src/contexts/AuthContext'
import {useEffect} from 'react'

const SuccessPage = () => {
  const {setLoginStatus} = useAuth()

  useEffect(() => {
    setLoginStatus(loginStatusEnum.noAuth)
  }, [setLoginStatus])

  return (
    <>
      <Logo />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="98vh"
      >
        <Paper
          elevation={3}
          sx={{
            p: '2rem',
            width: '25%',
            borderRadius: '16px'
          }}
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Stack>
              <SuccessIcon />
            </Stack>
            <Typography
              variant="h5"
              sx={{
                fontFamily: 'Heebo',
                pt: '2rem',
                fontWeight: 'bold'
              }}
            >
              המידע עודכן בהצלחה
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontFamily: 'Heebo',
                pt: '2rem',
                textAlign: 'center',
                color: '#637381'
              }}
            >
              הבקשה נבדקת כעת ע”י מערכת הניהול של ‘קרן עולם התורה’.
            </Typography>
          </Stack>
        </Paper>
      </Box>
    </>
  )
}

export default SuccessPage
