import {Divider, Stack} from '@mui/material'
import Institution from 'src/entities/Institution'
import TextDetails from '../custom/TextDetails'

interface InstitutionDetailsProps {
  institution?: Institution
}

const InstitutionDetails = ({institution}: InstitutionDetailsProps) => {
  return (
    <Stack direction="column" spacing={2}>
      <>
        <Stack
          spacing={4}
          width={'100%'}
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            textAlign: 'center',
            gap: 1,
            flexWrap: 'wrap'
          }}
        >
          <TextDetails label="סוג מוסד" text={institution?.typeDescription} />

          <Divider orientation="vertical" flexItem sx={{mx: 2}} />

          <TextDetails label="שם מוסד" text={institution?.name} />

          <Divider orientation="vertical" flexItem sx={{mx: 2}} />

          <TextDetails
            label="ראש כולל / ישיבה"
            text={institution?.institutionHeadName}
          />

          <Divider orientation="vertical" flexItem sx={{mx: 2}} />

          <TextDetails
            label="כתובת"
            text={
              institution?.street +
              ' ' +
              institution?.houseNumber +
              ' ' +
              institution?.city
            }
          />

          <Divider orientation="vertical" flexItem sx={{mx: 2}} />

          <TextDetails label="טלפון" text={institution?.phone} />
        </Stack>
      </>
    </Stack>
  )
}

export default InstitutionDetails
